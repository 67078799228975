:export {
  white: white;
  green: #34BFA3;
  gold: #BEA971;
  orange: #c3a248;
  red: #F21E5A;
  silver: #5D5D5D;
  grey: #5D5D5D;
  black: #000000; }

.react-confirm-alert {
  position: fixed;
  top: 110px; }
  .react-confirm-alert .react-confirm-alert-button-group {
    -webkit-box-pack: center;
            justify-content: center; }
    .react-confirm-alert .react-confirm-alert-button-group button:first-of-type {
      background-color: #34BFA3; }
    .react-confirm-alert .react-confirm-alert-button-group button:last-of-type {
      background-color: #F21E5A; }
